body[id^="Lead"].old-model .footer {
    padding-bottom: 50px;
}
body#Lead_index.old-model {
    margin-top: 0px !important;
}
body#Lead_index.old-model .tooltip .tooltip-inner {
    background: var(--color-lightest);
    color: var(--color-darkerst);
}
body#Lead_index.old-model .tooltip .arrow:before{
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8px 0 8px;
    border-color: var(--color-lightest) transparent transparent transparent;
}
body.old-model #lead-form * {
    --bs-limiter: 1502px;
    --bs-cols: 12;
    --bs-cols-image: 7;
    --bs-spacer: 20px;
}
body.old-model #lead-form .lead-image-container .image-container .desktop {
    width: 100%;
    max-width: calc((var(--bs-limiter) - ((var(--bs-cols) - 1) * var(--bs-spacer)))/var(--bs-cols)*var(--bs-cols-image) + ((var(--bs-cols-image) - 1) * var(--bs-spacer)) - 4px);
    /*transition: all 0.25s linear;*/
}
@media (max-width: 1620px) {
    body.old-model #lead-form * {
        --bs-limiter: calc(100vw - 120px);
    }    
}
body.old-model #lead-form .checkbox-options {
    padding: 10px 0 0;
}
body.old-model #lead-form .checkbox-options .form-check {
    padding-left: 2rem;
}
body.old-model #lead-form .form-check {
    margin-bottom: 8px;
}
body.old-model #lead-form .form-group,
body.old-model #lead-form .form--optin {
    margin-bottom: 24px;
}
body.old-model #lead-form .form--optin  p {
    margin-bottom: 24px;
}
body.old-model #lead-form .form--optin p a {
    font-weight: 700;
    text-decoration: underline;
    color: var(--color-light);
}
body.old-model #lead-form .lead-container {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-areas: "image content";
}
body.old-model #lead-form .lead-image-container {
    width: auto;
    height: auto;
}
body.old-model #lead-form .lead-image-container .image-container {
    width: auto;
    height: auto;
    position: sticky;
    top: 0;
}

body.old-model #lead-form .lead-image-container .mobile {
    display: none;
}
body.old-model #lead-form .lead-image-container .desktop {
    height: auto;
}
body.old-model #lead-form .lead-form-container {
    padding: 62px 70px 120px;
}
body.old-model #lead-form .lead-form-container h1 {
    margin: 0 0 10px;
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
}
body.old-model #lead-form .lead-form-container p {
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
}
body.old-model #lead-form .lead-form-container .form-submit p {
    font-size: 11px;
    line-height: 16px;
}
body.old-model #lead-form .lead-form-container .contaContent {
    margin: 30px 0 20px;
}
body.old-model #lead-form .lead-form-container .contaContent .base {
    margin: 0 0 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    font-weight: 300;
}
body.old-model #lead-form .lead-form-container .contaContent .btn-white {
    height: auto;
    border: 2px solid #000;
    padding: 9px 20px 7px;
    font-size: 12px;
    font-weight: 400;
}
body.old-model #lead-form .lead-form-container .contaContent .divider {
    display: flex;
    justify-content: stretch;
    align-items: center;
}
body.old-model #lead-form .lead-form-container .contaContent .divider:before,
body.old-model #lead-form .lead-form-container .contaContent .divider:after {
    width: 100%;
    height: 1px;
    content: "";
    display: block;
    content:"";
    background: #f1f1f1;
}
body.old-model #lead-form .lead-form-container .contaContent .divider span {
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
    text-align: center;
    padding: 0 12px;
    background: #fff;
    white-space: nowrap;
}
body.old-model #lead-form .form-group {
    position: relative;
}
body.old-model #lead-form .form-group .lock {
    position: absolute;
    bottom: 14px;
    right: 10px;
}
body.old-model #lead-form .lead-form-container .contaContent.logged {
    width: 100%;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f1f1f1;
}
body.old-model #lead-form .lead-form-container .contaContent.logged p {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
}
body.old-model #lead-form .lead-form-container .contaContent.logged p img {
    width: 19px;
    height: 19px;
    border-radius: 50%;
}
body.old-model #lead-form .lead-form-container .contaContent.logged p a {
    font-weight: 700;
    text-decoration: underline;
    color: var(--color-light);
}
body.old-model #lead-form .form-check label a {
    color: var(--color-darkest);
    text-decoration: underline;
}
body.old-model #lead-form .btn-darkest {
    width: 100%;
    padding: 15px 20px 13px;
    text-transform: uppercase;
    letter-spacing: 3px;
    border-radius: 0px;
}
body[id^="Lead"].old-model .footer {
    margin-top: 30px;
    padding-bottom: 50px;
}
body[id^="Lead"].old-model .footer p {
    margin: 0px;
    font-size: 12px;
    color: var(--color-lighter);
}
body[id^="Lead"].old-model .footer p a {
    font-size: 12px;
    color: var(--color-lighter);
    text-decoration: underline;
}
body#Lead_index.old-model form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
}
body#Lead_index.old-model  form [class^="form"] {
    grid-column: span 2;
}
body#Lead_index.old-model  form .form--number,
body#Lead_index.old-model  form .form--complement,
body#Lead_index.old-model  form .form--state,
body#Lead_index.old-model  form .form--city {
    grid-column: span 1;    
}
body#Lead_indexb.old-model  form .form--number,
body#Lead_indexb.old-model  form .form--complement,
body#Lead_indexb.old-model  form .form--state,
body#Lead_indexb.old-model  form .form--city {
    grid-column: span 2;    
}
@media (max-width: 1440px) {
    body.old-model #lead-form .lead-container {
        width: 100%;
        grid-template-columns: 1fr 1fr;
    }
    body.old-model #lead-form .lead-image-container .desktop {
        width: 100%;
        height: auto;
        object-fit: cover;
        object-position: right bottom;
    }
}
@media (max-width: 992px) {
    body.old-model #lead-form .lead-container {
        grid-template-columns: 100%;
        grid-template-areas: "image" "content";
    }
    body.old-model #lead-form .lead-form-container {
        padding: 30px 20px 50px;
    }
    body.old-model #lead-form .lead-image-container .desktop {
        display: none;
    }
    body.old-model #lead-form .lead-image-container .mobile {
        width: calc(100% + 56px);
        margin-left: -28px;
        margin-right: -28px;
        display: block;
    }
    body.old-model #lead-form .lead-form-container .contaContent .divider {
        margin-left: -20px;
        margin-right: -20px;
    }
    body[id^="Lead"].old-model .footer p {
        text-align: center;
    }
    body[id^="Lead"].old-model .footer .order-1,
    body[id^="Lead"].old-model .footer .order-2 {
        padding-bottom: 18px;
    }
    body#Lead_index.old-model  form .form--number,
    body#Lead_index.old-model  form .form--complement,
    body#Lead_index.old-model  form .form--state,
    body#Lead_index.old-model  form .form--city {
        grid-column: span 2;    
    }
}
@media  (max-width: 440px) {
    body.old-model #lead-form h1,
    body.old-model #lead-form .lead-form-container h1
    {
        font-size: 26px;
        line-height: 28px;
    }
}
body.old-model .lead-box-container {
    width: calc(100% - 40px);
    max-width: 1118px;
    margin: calc(var(--mainmenu-height) + 120px) auto 120px;
    display: grid;
    grid-template-columns: minmax(20%,488px) 1fr;
    grid-template-areas: "image content";
    -webkit-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.16); 
    box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.16);
}
body.old-model .lead-box-container .lead-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: bottom center;
}
body.old-model .lead-box-container .lead-message-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    flex-direction: column;
    padding: 20px 100px;
}
body.old-model .lead-box-container .lead-message-container h1 {
    margin: 0 0 10px;
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
}
body.old-model .lead-box-container .lead-message-container p {
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
}
body.old-model .lead-box-container .lead-message-container .form-submit {
    width: 100%;
}
body.old-model .lead-box-container .lead-message-container .btn-darkest {
    width: 100%;
    align-self: stretch;
    padding: 15px 20px 13px;
    text-transform: uppercase;
    letter-spacing: 3px;
    border-radius: 0px;
}
@media (max-width: 992px) {
    body.old-model .lead-box-container {
        width: 100%;
        margin: 0 0 100px;
        display: grid;
        grid-template-columns: 100%;
        grid-template-areas: "image" "content";
        -webkit-box-shadow: none; 
        box-shadow: none;
    }
    body.old-model .lead-box-container .lead-message-container {
        padding: 26px 20px 0;
    }
}
body.old-model .grecaptcha-badge {
    visibility: hidden;
}

body.old-model .lead-form-container .video-container {
    overflow: hidden;
    position: relative;
    width:100%;
}

body.old-model .lead-form-container .video-container::after {
    padding-top: 56.25%;
    display: block;
    content: '';
}

body.old-model .lead-form-container .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

body.old-model #lead-form .lead-container .display-block{
    display: block !important;
}

#formLead .select2-container--default .select2-selection--single {
    border: none;
    background: none;
    border-radius: 0px;
}
#formLead .select2-container .select2-selection--single .select2-selection__rendered {
    padding: 0 1rem 0.5rem 1rem;
    font-size: 1rem;
    border-bottom: 1px solid var(--color-dark);
}
#formLead .select2-container--default .select2-selection--single .select2-selection__arrow b {
    width: 6.5px !important;
    height: 6.5px !important;
    border-color: #666 !important;
    border-style: solid !important;
    border-width: 2px 2px 0 0 !important;
    transform: rotate(135deg) !important;
}
#formLead .form-group.form--city > label 
{
    width: 100%;
}

#formLead .form-group.form--value2 > label 
{
    width: 100%;
}

#formLead .select2.select2-container.select2-container--default
{
    width: 100% !important;
}

#formLead .select2.select2-container.select2-container--default.select2-container--below.select2-container--focus
{
    width: 100% !important;
}

#formLead .select2.select2-container.select2-container--default.select2-container--below.select2-container--open
{
    width: 100% !important;
}

#formLead .form-group.form--city .select2.select2-container.select2-container--default.select2.select2-container.select2-container--default.select2-container--below
{
    width: 100% !important;
}

#formLead .select2-container--default .select2-search--dropdown .select2-search__field {
    background-color: white;
}